import React from 'react';
import { List, ListSubheader } from '@material-ui/core';
import ListItemLink from './ListItemLink';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

export default function DeviceList(props) {
  const { userId } = props;
  const devicesRef = useFirestore().collection(`users/${userId}/devices`);

  const { status, data } = useFirestoreCollectionData(devicesRef);

  if (status === 'loading') return null;
  const devices = data;

  return (
    <List>
      <ListSubheader>Devices</ListSubheader>
      {devices &&
        devices.map((device) => (
          <ListItemLink
            key={device.NO_ID_FIELD}
            primary={device.nickname || device.NO_ID_FIELD}
            secondary={device.nickname ? device.NO_ID_FIELD : undefined}
            to={`/users/${userId}/devices/${device.NO_ID_FIELD}`}
          />
        ))}
    </List>
  );
}

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class UserPage extends Component {
  state = {};
  render() {
    console.log(this.props);
    return <div>User</div>;
  }
}

export default withRouter(UserPage);

import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { firebaseApp } from '../firebase';
import APIKeyMenu from './settings/APIKeyMenu';
import EmailList from './settings/EmailList';
import { Card, CardHeader, CardContent } from '@material-ui/core';

export default function SettingsPage(props) {
  const { userId } = useParams();
  const authorized = userId === firebaseApp.auth().currentUser?.uid;

  return (
    <div>
      {authorized ? (
        <Card>
          <CardHeader title="User Settings" />
          <APIKeyMenu userId={userId} />
          <CardContent>
            <EmailList userId={userId} listName="alert-email-list" />
          </CardContent>
        </Card>
      ) : (
        <Redirect to="/" />
      )}
    </div>
  );
}

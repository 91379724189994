import React from 'react';
import { Box, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { useFirestoreDocData } from 'reactfire';
import { CheckCircle, Error } from '@material-ui/icons';

const alertTypeLabelMap = {
  range: 'Field out of range',
  'range-resolved': 'Field in range',
};

export default function AlertItem(props) {
  const { docRef } = props;

  const { status, data } = useFirestoreDocData(docRef);

  if (status === 'loading') return null; // TODO: placeholder

  let icon;
  switch (data.type) {
    case 'range': {
      icon = <Error fontSize="large" />;
      break;
    }
    case 'range-resolved': {
      icon = <CheckCircle fontSize="large" />;
      break;
    }
    default:
  }

  const timestamps = (
    <Box
      display="flex"
      flexDirection="column"
      component="span"
      textAlign="right"
    >
      <span>{data.timestamp.toDate().toLocaleTimeString()}</span>
      <span>{data.timestamp.toDate().toLocaleDateString()}</span>
    </Box>
  );

  const SecondaryContent = () => {
    const isMin = data.min || data.min === 0;
    switch (data.type) {
      case 'range':
        return (
          <>
            <span>Field value = {data.value}</span>
            <span>
              {isMin ? 'Min' : 'Max'} value = {isMin ? data.min : data.max}
            </span>
          </>
        );
      case 'range-resolved':
        return <span>Alert resolved</span>;
      default:
        return null;
    }
  };

  return (
    <ListItem>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText
        primary={alertTypeLabelMap[data.type]}
        secondary={
          <Box display="flex" justifyContent="space-between" component="span">
            <Box display="flex" flexDirection="column" component="span">
              <SecondaryContent />
            </Box>
            {timestamps}
          </Box>
        }
      />
    </ListItem>
  );
}

import { NavLink } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

function ListItemLink(props) {
  const { icon, to, ...rest } = props;

  return (
    <ListItem button to={to} component={NavLink}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText {...rest} />
    </ListItem>
  );
}

export default ListItemLink;

import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TextField,
  Select,
  Menu,
  MenuItem,
  Button,
  CircularProgress,
  ButtonGroup,
  Tooltip,
} from '@material-ui/core';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Add, Edit, Check, Delete, Close, MoreHoriz } from '@material-ui/icons';
import DelayedDisplay from '../../common/DelayedDisplay';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { useParams } from 'react-router-dom';

const eventValueToState = (stateHook) => (event) =>
  stateHook(event.target.value);

const FieldLimitRow = ({
  field,
  limits: { min = '', max = '' },
  docRef,
  fieldNames,
  onRemoveUnsaved,
}) => {
  const [fieldValue, changeFieldValue] = useState(field);
  const [minValue, changeMinValue] = useState(min);
  const [maxValue, changeMaxValue] = useState(max);

  const existingFieldsSet = field && (min || min === 0 || max || max === 0);

  const validMinMax =
    (minValue === '' && maxValue !== '') ||
    (minValue !== '' && maxValue === '') ||
    Number(minValue) < Number(maxValue);
  const fieldValError = !fieldValue;
  const minValError = isNaN(minValue) || !validMinMax;
  const maxValError = isNaN(maxValue) || !validMinMax;

  const validInput = !fieldValError && !minValError && !maxValError;
  const madeChanges =
    minValue !== min || maxValue !== max || fieldValue !== field;

  const [editing, setEditing] = useState(!validInput);

  const handleSave = async () => {
    if (madeChanges) {
      const limits = {};
      if (minValue !== '') limits.min = Number(minValue);
      if (maxValue !== '') limits.max = Number(maxValue);
      await docRef.set({
        field: fieldValue,
        limits,
      });
    }
    setEditing(false);
  };
  const handleEdit = () => {
    setEditing(true);
  };
  const handleCancel = () => {
    setEditing(false);
    changeFieldValue(field);
    changeMinValue(min);
    changeMaxValue(max);
    if (!existingFieldsSet) onRemoveUnsaved();
  };
  const handleDelete = () => {
    docRef.delete();
  };

  return (
    <TableRow key={docRef.id}>
      <TableCell>
        <Select
          value={editing ? fieldValue : field}
          disabled={!editing}
          onChange={eventValueToState(changeFieldValue)}
          error={fieldValError}
        >
          {fieldNames.map((fieldName) => (
            <MenuItem key={fieldName} value={fieldName}>
              {fieldName}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell>
        <TextField
          value={editing ? minValue : min}
          disabled={!editing}
          onChange={eventValueToState(changeMinValue)}
          type="number"
          size="small"
          error={minValError}
        />
      </TableCell>
      <TableCell>
        <TextField
          value={editing ? maxValue : max}
          disabled={!editing}
          onChange={eventValueToState(changeMaxValue)}
          type="number"
          size="small"
          error={maxValError}
        />
      </TableCell>
      <TableCell align="right" size="small">
        {!editing ? (
          <PopupState popupId={`popup-${docRef.id}`} variant="popover">
            {(popupState) => (
              <React.Fragment>
                <IconButton {...bindTrigger(popupState)} size="small">
                  <MoreHoriz />
                </IconButton>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={() => {
                      popupState.close();
                      handleEdit();
                    }}
                  >
                    <Edit fontSize="small" />
                    &nbsp;Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      popupState.close();
                      handleDelete();
                    }}
                  >
                    <Delete fontSize="small" />
                    &nbsp;Delete
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        ) : (
          <ButtonGroup size="small" variant="text">
            <Tooltip title="Save">
              <Button
                onClick={handleSave}
                disabled={!validInput || !madeChanges}
                color="primary"
              >
                <Check />
              </Button>
            </Tooltip>
            <Tooltip title="Cancel">
              <Button onClick={handleCancel} color="default">
                <Close />
              </Button>
            </Tooltip>
          </ButtonGroup>
        )}
      </TableCell>
    </TableRow>
  );
};

export default function FieldLimitMenu(props) {
  const { fieldNames } = props;
  const { userId, deviceId } = useParams();

  const rulesRef = useFirestore().collection(
    `users/${userId}/devices/${deviceId}/field-limits`
  );

  const { status, data: rawData } = useFirestoreCollection(rulesRef);
  const [fieldLimits, updateFieldLimits] = useState(undefined);

  useEffect(() => {
    if (status === 'success') {
      const data = rawData.docs.map((doc) => ({
        docRef: doc.ref,
        ...doc.data(),
      }));
      updateFieldLimits(data);
    }
  }, [status, rawData]);

  const handleAddRule = () => {
    const doc = rulesRef.doc();
    const newLimits = [
      ...fieldLimits,
      {
        docRef: doc,
        field: '',
        limits: { min: '', max: '' },
      },
    ];
    updateFieldLimits(newLimits);
  };

  const handleRemoveUnsaved = (val) => {
    updateFieldLimits(fieldLimits.filter((other) => other !== val));
  };

  return !fieldLimits ? (
    <DelayedDisplay seconds={1}>
      <CircularProgress />
    </DelayedDisplay>
  ) : (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Field</TableCell>
          <TableCell>Min. value</TableCell>
          <TableCell>Max. value</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {fieldLimits.map((val) => (
          <FieldLimitRow
            key={val.docRef.id}
            {...val}
            fieldNames={fieldNames}
            onRemoveUnsaved={() => handleRemoveUnsaved(val)}
          />
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell colSpan={2} size="small"></TableCell>
          <TableCell colSpan={2} align="right" size="small">
            <Button onClick={handleAddRule} startIcon={<Add />}>
              New&nbsp;rule
            </Button>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
}

import { Paper } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { Link as LinkIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import React from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire';

const columns = [
  {
    field: 'link',
    headerName: 'Link',
    width: 50,
    renderCell: (params) => (
      <Link to={params.value} style={{ marginTop: '0.75em' }}>
        <LinkIcon />
      </Link>
    ),
  },
  { field: 'id', headerName: 'Device ID', width: 150 },
  { field: 'nickname', headerName: 'Nickname', width: 175, editable: true },
  {
    field: 'active',
    headerName: 'Active',
    width: 100,
    type: 'boolean',
    editable: true,
  },
  { field: 'online', headerName: 'Online', width: 100, type: 'boolean' },
  {
    field: 'created',
    headerName: 'Created',
    width: 180,
    type: 'Date',
    valueFormatter: ({ value }) => value.toLocaleString(),
  },
  {
    field: 'lastConnection',
    headerName: 'Latest Connection',
    width: 180,
    type: 'DateTime',
    valueFormatter: ({ value }) => value.toLocaleString(),
  },
  { field: 'ts-channel', headerName: 'ThingSpeak ID', width: 150 },
];

export default function HomePage(props) {
  const { userId } = props;
  const devicesRef = useFirestore().collection(`users/${userId}/devices`);

  const onCommitEdit = (params, event) => {
    let value = params.props.value || '';
    const ref = devicesRef.doc(params.id);

    switch (params.field) {
      case 'nickname': {
        ref.update({ nickname: value });
        break;
      }
      case 'active': {
        ref.update({ active: value || false });
        break;
      }
      default:
    }
  };

  const { status, data } = useFirestoreCollectionData(devicesRef);

  let rows = [];
  if (status === 'success') {
    rows = data.map((device) => ({
      link: `/users/${userId}/devices/${device.NO_ID_FIELD}`,
      id: device.NO_ID_FIELD,
      nickname: device.nickname,
      active: device.active,
      online: device['recent-connection'],
      created: new Date(device.created?.seconds * 1000),
      lastConnection: new Date(device['last-connection']?.seconds * 1000),
      'ts-channel': device['ts-channel'],
    }));
  }

  return (
    <Paper>
      <div style={{ height: '87.5vh' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          components={{
            Toolbar: GridToolbar,
          }}
          disableColumnMenu={true}
          disableColumnReorder={true}
          autoPageSize
          density="compact"
          error={status === 'error' ? true : null}
          loading={status === 'loading'}
          onEditCellChangeCommitted={onCommitEdit}
        />
      </div>
    </Paper>
  );
}

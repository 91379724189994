import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import {
  Button,
  Divider,
  Card,
  CardHeader,
  CardContent,
  TextField,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  FormControl,
  Typography,
  Switch,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Save } from '@material-ui/icons';
import DelayedDisplay from '../common/DelayedDisplay';
import FieldLimitMenu from './device/FieldLimitMenu';
import AlertList from './device/AlertList';
import { Grid } from '@material-ui/core';
import { functions } from '../firebase';

const useStyles = makeStyles({
  loaderRoot: {
    display: 'flex',
    justifyContent: 'center',
  },
  monospaceInput: {
    '& input.MuiInputBase-input': {
      fontFamily: 'monospace !important',
    },
  },
  noCursor: {
    '& input.MuiInputBase-input:focus': {
      caretColor: 'transparent',
    },
  },
  inputWithButtonRoot: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',

    '& button': {
      marginLeft: '1em',
    },
  },
});

export default function DevicePage(props) {
  const { userId, deviceId } = useParams();
  const classes = useStyles();
  const deviceRef = useFirestore().doc(`users/${userId}/devices/${deviceId}`);

  const { status, data: deviceData } = useFirestoreDocData(deviceRef);
  const [nicknameInput, updateNicknameInput] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    if (status === 'success') {
      updateNicknameInput(deviceData.nickname || '');
    }
  }, [status, deviceData]);

  const handleChangeActive = async () => {
    await deviceRef.update({ active: !deviceData.active });
  };

  const handleUpdateNickname = () => {
    if (deviceData.nickname === nicknameInput || !nicknameInput) return;

    deviceRef.update({
      nickname: nicknameInput,
    });
  };

  const handleDeleteDevice = async () => {
    if (!alertOpen) return;
    const deleteDevice = functions.httpsCallable('deleteDevice');
    await deleteDevice({
      uid: userId,
      id: deviceId,
    });
    setAlertOpen(false);
  };

  const notFound = (
    <Typography variant="h6" component="h1" align="center">
      No device found
    </Typography>
  );

  if (status === 'error') return notFound;

  if (status === 'loading')
    return (
      <div className={classes.loaderRoot}>
        <DelayedDisplay seconds={1}>
          <CircularProgress />
        </DelayedDisplay>
      </div>
    );

  if (Object.keys(deviceData).length <= 1) return notFound;

  const {
    active,
    'recent-connection': recentConnection,
    'last-connection': lastConnection,
    'ts-channel': tsChannel,
    'ts-fields': tsFields,
    nickname,
  } = deviceData;

  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 7);
  startDate.setHours(0, 0, 0, 0);

  return (
    <>
      <Dialog
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete this device?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Any ThingSpeak channel associated with this device will also be
            deleted. This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteDevice} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Card>
        <CardHeader title="Device Settings" subheader={deviceId} />
        <CardContent>
          <FormGroup>
            <FormControl className={classes.inputWithButtonRoot}>
              <TextField
                variant="outlined"
                size="small"
                value={nicknameInput}
                onChange={(e) => updateNicknameInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) handleUpdateNickname();
                }}
                label="Nickname"
                placeholder="Device nickname"
                fullWidth
              />
              <Button
                variant="outlined"
                startIcon={<Save />}
                onClick={handleUpdateNickname}
                disabled={nickname === nicknameInput || !nicknameInput}
              >
                Save
              </Button>
            </FormControl>
            <Divider style={{ marginBottom: '1em', marginTop: '1.5em' }} />
            <FormControl>
              <FormControlLabel
                label="Device Active"
                control={
                  <Switch
                    color="primary"
                    checked={active}
                    onChange={handleChangeActive}
                  />
                }
              />
              <FormHelperText>Active devices transmit alerts.</FormHelperText>
            </FormControl>
            <Divider style={{ marginBottom: '1.5em', marginTop: '1em' }} />
            <Grid container justify="space-between">
              <Typography variant="caption">
                <Link
                  href={`https://thingspeak.com/channels/${tsChannel}/private_show`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  ThingSpeak channel {tsChannel}
                </Link>
              </Typography>
              <Typography variant="caption">
                Last seen {lastConnection.toDate().toLocaleString()}
              </Typography>
            </Grid>
          </FormGroup>
        </CardContent>
      </Card>
      <br />
      <Card>
        <CardHeader
          title="Alert Status"
          subheader={`Reported since ${startDate.toLocaleDateString()}`}
        />
        <CardContent>
          {active ? (
            <AlertList
              fieldNames={Object.keys(tsFields)}
              startDate={startDate}
              connectionAlertActive={!recentConnection}
              lastConnectionTime={lastConnection.toDate()}
              activeFieldAlerts={deviceData['field-alerts']}
            />
          ) : (
            <Typography variant="subtitle1" align="center">
              Activate the device to enable alerts
            </Typography>
          )}
        </CardContent>
      </Card>
      <br />
      <Card>
        <CardHeader
          title="Field Limits"
          subheader="Define the expected values of fields"
        />
        <FieldLimitMenu fieldNames={Object.keys(tsFields)} />
      </Card>
      <br />
      <Card>
        <CardHeader
          title="Danger Zone"
          subheader="These actions are not reversible"
        />
        <CardContent>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="body1">Delete this device</Typography>
              <Typography variant="body2" color="textSecondary">
                Delete this device and its associated ThingSpeak channel
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => setAlertOpen(true)}>
                Delete this device
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

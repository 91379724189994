import React from 'react';
import { StyledFirebaseAuth } from 'react-firebaseui';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import firebase, { firebaseApp } from './firebase';

import MenuAppBar from './common/MenuAppBar';
import DeviceList from './common/DeviceList';
import ResponsiveDrawer from './common/ResponsiveDrawer';
import ListItemLink from './common/ListItemLink';
import DelayedDisplay from './common/DelayedDisplay';

import HomePage from './pages/HomePage';
import UserPage from './pages/UserPage';
import DevicePage from './pages/DevicePage';
import SettingsPage from './pages/SettingsPage';

import { Divider, List, Typography } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import deepPurple from '@material-ui/core/colors/deepPurple';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: indigo[500],
    },
    secondary: {
      main: deepPurple[500],
    },
  },
});

class App extends React.Component {
  uiConfig = {
    signInOptions: [
      // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },
  };

  state = {
    isSignedIn: undefined,
  };

  componentDidMount() {
    this.unregisterAuthObserver = firebaseApp
      .auth()
      .onAuthStateChanged(async (user) => {
        this.setState({ isSignedIn: !!user });
        if (!user) return;
        const tokenResult = await user.getIdTokenResult().then();
        this.setState({ isAdmin: !!tokenResult.claims.admin });
      });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    const { isSignedIn, isAdmin } = this.state;
    const signedOut = isSignedIn !== undefined && !isSignedIn;
    const auth = firebaseApp.auth();

    return (
      <Router>
        <ThemeProvider theme={theme}>
          <ResponsiveDrawer
            AppBar={(props) => (
              <MenuAppBar
                title="Dashboard"
                signedIn={isSignedIn}
                admin={isAdmin}
                onSignOut={() => auth.signOut()}
                displayName={auth.currentUser?.displayName}
                {...props}
              />
            )}
            DrawerContent={() => (
              <>
                <List>
                  <ListItemLink primary={'Home'} to="/" icon={<HomeIcon />} />
                  {isSignedIn && (
                    <ListItemLink
                      primary={'Settings'}
                      to={`/users/${auth.currentUser?.uid}/settings`}
                      icon={<SettingsIcon />}
                    />
                  )}
                </List>
                <Divider />
                {isSignedIn && <DeviceList userId={auth.currentUser?.uid} />}
              </>
            )}
            PageContent={() =>
              signedOut ? (
                <StyledFirebaseAuth
                  uiConfig={this.uiConfig}
                  firebaseAuth={auth}
                />
              ) : (
                <Switch>
                  <Route exact path="/">
                    <HomePage userId={auth.currentUser?.uid} />
                  </Route>
                  <Route exact path="/users/:userId/settings">
                    <SettingsPage />
                  </Route>
                  <Route exact path="/users/:userId/devices/:deviceId">
                    <DevicePage />
                  </Route>
                  <Route exact path="/users/:userId">
                    <UserPage />
                  </Route>
                  <Route>
                    <DelayedDisplay seconds={1}>
                      <Typography variant="h1" align="center">
                        404
                      </Typography>
                      <Typography variant="subtitle1" align="center">
                        Page Not Found
                      </Typography>
                    </DelayedDisplay>
                  </Route>
                </Switch>
              )
            }
          />
        </ThemeProvider>
      </Router>
    );
  }
}

export default App;

import { useState } from 'react';
import {
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  TextField,
  Hidden,
  CircularProgress,
  makeStyles,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { Cached, Check, Info } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { functions } from '../../firebase';
import MouseOverPopover from '../../common/MouseOverPopover';
import CopyButton from '../../common/CopyButton';
import DelayedDisplay from '../../common/DelayedDisplay';
import { useFirestore, useFirestoreDocData } from 'reactfire';

const useStyles = makeStyles({
  monospaceInput: {
    '& input.MuiInputBase-input': {
      fontFamily: 'monospace !important',
    },
  },
  noCursor: {
    '& input.MuiInputBase-input:focus': {
      caretColor: 'transparent',
    },
  },
});

export default function AuthUserSettingsPage(props) {
  const [tsUserKeyInput, updateUserKeyInput] = useState('');
  const [keyToReset, setKeyToReset] = useState('');

  const keysRef = useFirestore().doc(`users/${props.userId}/private/keys`);
  const { status, data: privateKeys } = useFirestoreDocData(keysRef);

  const classes = useStyles();
  const { userId } = props;

  useEffect(() => {
    if (status === 'success') {
      updateUserKeyInput(privateKeys['ts-user-key'] || '');
    }
  }, [status, privateKeys]);

  const [validInput, updateValidInput] = useState(true);

  const handleUserKeyInputChange = async (event) => {
    const regex = /[\dA-Z]/g;
    let keyInput = String(event.target.value).toUpperCase();
    keyInput = keyInput.match(regex)?.join('') || '';
    updateUserKeyInput(keyInput);

    if (keyInput.length !== 16) return updateValidInput(false);

    const tsResponse = await fetch(
      `https://api.thingspeak.com/channels.json?api_key=${keyInput}`,
      { method: 'GET' }
    );

    updateValidInput(tsResponse.ok);
  };

  const inputValidator = (input) =>
    /[\dA-Z]{16}/.test(input) && input.length === 16;

  const handleSaveUserKey = () => {
    if (!inputValidator(tsUserKeyInput)) return;

    keysRef.update({
      'ts-user-key': tsUserKeyInput,
    });
  };
  const keyChanged =
    privateKeys && tsUserKeyInput !== privateKeys['ts-user-key'];

  const handleResetKey = () => {
    if (!keyToReset) return;
    const resetAppKey = functions.httpsCallable('resetAppKey');
    resetAppKey({
      uid: props.userId,
      keyType: keyToReset,
    });
    setKeyToReset('');
  };

  const makeRow = (label, key, text, keyType) => (
    <TableRow key={label}>
      <TableCell component="th" scope="row">
        <Grid container direction="row" alignItems="center" wrap="nowrap">
          <Grid item>{label}</Grid>
          <Hidden xsDown>
            <Grid item>&nbsp;</Grid>
            <Grid item>
              <MouseOverPopover
                text={text}
                mouseOverContent={<Info fontSize="small" color="disabled" />}
              />
            </Grid>
          </Hidden>
        </Grid>
      </TableCell>
      <TableCell align="right">
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="flex-end"
          wrap="nowrap"
        >
          <TextField
            className={classes.monospaceInput + ' ' + classes.noCursor}
            variant="outlined"
            value={key}
            fullWidth
          />
          <Hidden xsDown>
            <Grid item>&nbsp;</Grid>
            <Grid item>
              <CopyButton copyContent={key} />
            </Grid>
          </Hidden>
        </Grid>
      </TableCell>
      <TableCell align="right">
        {!!keyType && (
          <Tooltip title="Generate new key">
            <IconButton onClick={() => setKeyToReset(keyType)}>
              <Cached />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );

  return (
    <>
      <Dialog
        open={keyToReset !== ''}
        onClose={() => setKeyToReset('')}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Generate a new key?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Any devices using the old key will need to be updated.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setKeyToReset('')}>Cancel</Button>
          <Button onClick={handleResetKey} color="primary" autoFocus>
            Generate
          </Button>
        </DialogActions>
      </Dialog>
      {Boolean(privateKeys) ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>API Keys</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {makeRow(
              'User ID',
              userId,
              'Your unique user ID. Used in API requests',
              null
            )}
            {makeRow(
              'Device Update Key',
              privateKeys['device-update-key'],
              'The key used to post data via the API',
              'update'
            )}
            <TableRow>
              <TableCell component="th" scope="row">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  wrap="nowrap"
                >
                  <Grid item>ThingSpeak User Key</Grid>
                  <Hidden xsDown>
                    <Grid item>&nbsp;</Grid>
                    <Grid item>
                      <MouseOverPopover
                        text={
                          "The 'User API Key' from the ThingSpeak profile page"
                        }
                        mouseOverContent={
                          <Info fontSize="small" color="disabled" />
                        }
                      />
                    </Grid>
                  </Hidden>
                </Grid>
              </TableCell>
              <TableCell align="right">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="flex-end"
                  wrap="nowrap"
                >
                  <TextField
                    required
                    className={classes.monospaceInput}
                    variant="outlined"
                    placeholder="ThingSpeak User Key"
                    value={tsUserKeyInput}
                    onChange={handleUserKeyInputChange}
                    autoComplete="false"
                    type="text"
                    error={!validInput}
                    fullWidth
                  />

                  <Hidden xsDown>
                    <Grid item>&nbsp;</Grid>
                    <Grid item>
                      <CopyButton copyContent={tsUserKeyInput} />
                    </Grid>
                  </Hidden>
                </Grid>
              </TableCell>
              <TableCell align="right">
                <Tooltip title="Update key">
                  <span>
                    <IconButton
                      disabled={!keyChanged || !validInput}
                      color="primary"
                      onClick={handleSaveUserKey}
                    >
                      <Check />
                    </IconButton>
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        <DelayedDisplay seconds={1}>
          <CircularProgress />
        </DelayedDisplay>
      )}
    </>
  );
}

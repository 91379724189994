import React from 'react';
import {
  Chip,
  Box,
  IconButton,
  Typography,
  TextField,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import { Add, Clear, Check } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import DelayedDisplay from '../../common/DelayedDisplay';

const useStyles = makeStyles({
  emailList: {
    marginTop: '0.75em',

    '& div': {
      marginLeft: '1em',
    },
    '& div:first-child': {
      marginLeft: '0',
    },
    '& button': {
      marginLeft: '0.5em',
    },
  },
});

export default function EmailList(props) {
  const { userId, listName } = props;
  const contactDocRef = useFirestore().doc(`users/${userId}/private/contact`);
  const classes = useStyles();
  const inputRef = useRef();

  const [editing, setEditing] = useState(false);
  const [emailInput, setEmailInput] = useState('');

  useEffect(() => {
    if (editing) inputRef.current.focus();
  }, [editing]);

  const { status, data } = useFirestoreDocData(contactDocRef);

  const { [listName]: emailList } = data || {};
  const emails = emailList?.split(/,\s*/).filter((e) => e !== '') || [];

  const regex = /\S+@\S+\.\S+/g;
  const validInput = regex.test(emailInput) && !emails.includes(emailInput);

  const handleEmailInput = (event) => {
    setEmailInput(String(event.target.value).trim());
  };

  const handleDelete = (email) => {
    const newEmails = emails.filter((other) => other !== email);
    const emailListString = newEmails.join(', ');
    contactDocRef.update({ [listName]: emailListString });
  };

  const handleSave = () => {
    setEditing(false);
    setEmailInput('');

    if (validInput) {
      const newEmails = [...emails, emailInput];
      const emailListString = newEmails.join(', ');
      contactDocRef.update({ [listName]: emailListString });
    }
  };

  const handleAdd = () => {
    setEditing(true);
  };

  const handleInputKeyDown = (e) => {
    switch (e.key) {
      case 'Enter': {
        if (validInput) handleSave();
        break;
      }
      case 'Escape': {
        setEditing(false);
        setEmailInput('');
        break;
      }
      default:
    }
  };

  return (
    <>
      <Typography fontWeight="bold">Alert email list:</Typography>
      {status === 'success' ? (
        <Box className={classes.emailList} display="flex" alignItems="center">
          {emails.length > 0
            ? emails.map((email) => (
                <Chip
                  key={email}
                  label={email}
                  onDelete={() => handleDelete(email)}
                  variant="outlined"
                />
              ))
            : !editing && (
                <Typography variant="caption" color="textSecondary">
                  No email addresses
                </Typography>
              )}
          {editing && (
            <TextField
              placeholder="Email address"
              size="small"
              value={emailInput}
              onChange={handleEmailInput}
              onKeyDown={handleInputKeyDown}
              error={!validInput}
              inputRef={inputRef}
            />
          )}
          <Tooltip
            title={
              editing ? (validInput ? 'Save' : 'Cancel') : 'Add email address'
            }
          >
            <IconButton
              size="small"
              type="email"
              onClick={() => (editing ? handleSave() : handleAdd())}
            >
              {editing ? validInput ? <Check /> : <Clear /> : <Add />}
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <Box display="flex" justifyContent="center">
          <DelayedDisplay seconds={1}>
            <CircularProgress />
          </DelayedDisplay>
        </Box>
      )}
    </>
  );
}

import { useState, useEffect } from 'react';

export default function DelayedDisplay(props) {
  const { seconds, children } = props;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setVisible(true), Number(seconds) * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [seconds]);

  return visible && children;
}

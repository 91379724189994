import React from 'react';
import {
  List,
  Typography,
  Divider,
  useTheme,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Tooltip,
} from '@material-ui/core';
import { useFirestore, useFirestoreCollection } from 'reactfire';
import { useParams } from 'react-router-dom';
import AlertItem from './AlertItem';
import { Cancel, CheckCircle, Error } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import { useState } from 'react';

export default function AlertList(props) {
  const {
    fieldNames,
    activeFieldAlerts,
    connectionAlertActive,
    startDate,
    lastConnectionTime,
  } = props;
  const { userId, deviceId } = useParams();
  const [selectedField, setSelectedField] = useState('');

  const alertCollectionRef = useFirestore()
    .collection(`users/${userId}/devices/${deviceId}/alerts`)
    .where('timestamp', '>=', startDate);

  const { status, data, error } = useFirestoreCollection(alertCollectionRef);

  if (status === 'loading') return null; // TODO: placeholder
  if (status === 'error') {
    console.error(error);
    return null;
  }

  const fieldErrorLevels = {};
  fieldNames.sort();
  fieldNames.forEach((field) => {
    const oneDayAgo = new Date();
    oneDayAgo.setDate(oneDayAgo.getDate() - 1);

    let fieldError;
    const fieldErrorIndex = data.docs.findIndex(
      (doc) =>
        doc.data().type === 'range' &&
        doc.data().field === field &&
        doc.data().timestamp?.toDate() > oneDayAgo
    );
    if (fieldErrorIndex !== -1) {
      fieldError = data.docs[fieldErrorIndex];
    }
    let fieldErrorStatus;

    if (activeFieldAlerts && activeFieldAlerts.indexOf(field) !== -1) {
      fieldErrorStatus = 'error';
    } else if (fieldError) {
      fieldErrorStatus = 'warn';
    } else {
      fieldErrorStatus = 'ok';
    }
    fieldErrorLevels[field] = fieldErrorStatus;
  });

  const filteredAlerts = data.docs.filter(
    (alert) => alert.data().field === selectedField
  );
  filteredAlerts.sort((a, b) => b.data().timestamp - a.data().timestamp);

  return (
    <Grid container direction="row" spacing={4}>
      <Grid item xs={12} sm={5} md={3}>
        <List>
          <ListItem
            button
            onClick={() => setSelectedField(selectedField === 't' ? '' : 't')}
            selected={selectedField === 't'}
          >
            <ListItemIcon>
              <StatusIcon status={connectionAlertActive ? 'error' : 'ok'} />
            </ListItemIcon>
            <ListItemText primary="Connection" />
          </ListItem>
          {fieldNames.map((name) => (
            <ListItem
              key={name}
              button
              onClick={() =>
                setSelectedField(selectedField === name ? '' : name)
              }
              selected={selectedField === name}
            >
              <ListItemIcon>
                <StatusIcon status={fieldErrorLevels[name]} />
              </ListItemIcon>
              <ListItemText primary={`Field ${name}`} />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Hidden xsDown>
        <Divider orientation="vertical" flexItem />
      </Hidden>
      <Hidden smUp>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Hidden>
      <Grid
        item
        style={{ flexGrow: 1, maxHeight: '75vh', overflowY: 'scroll' }}
      >
        {selectedField === '' ? (
          <Typography variant="subtitle1" align="center">
            No field selected
          </Typography>
        ) : selectedField === 't' ? (
          <List>
            <ListItem>
              <ListItemIcon>
                {connectionAlertActive ? (
                  <Error fontSize="large" />
                ) : (
                  <CheckCircle fontSize="large" />
                )}
              </ListItemIcon>
              <ListItemText
                primary={`Device ${
                  connectionAlertActive ? 'offline' : 'online'
                }`}
                secondary={
                  <>
                    <span>
                      Device has {connectionAlertActive && 'not'} checked in
                      recently
                    </span>
                    <br />
                    <span>
                      Last connected {lastConnectionTime.toLocaleString()}
                    </span>
                  </>
                }
              />
            </ListItem>
          </List>
        ) : filteredAlerts.length === 0 ? (
          <Typography variant="subtitle1" align="center">
            No alerts during this time
          </Typography>
        ) : (
          <List>
            {filteredAlerts.map((alert, index) => (
              <React.Fragment key={alert.id}>
                <AlertItem docRef={alert.ref} />
                {index < filteredAlerts.length - 1 && (
                  <Divider component="li" />
                )}
              </React.Fragment>
            ))}
          </List>
        )}
      </Grid>
    </Grid>
  );
}

function StatusIcon({ status, ...rest }) {
  const { palette } = useTheme();

  return status === 'error' ? (
    <Tooltip title="Active alert">
      <Cancel htmlColor={palette.error.main} {...rest} />
    </Tooltip>
  ) : status === 'warn' ? (
    <Tooltip title="Recent alert">
      <Error htmlColor={palette.warning.main} {...rest} />
    </Tooltip>
  ) : (
    <Tooltip title="OK">
      <CheckCircle htmlColor={palette.success.main} {...rest} />
    </Tooltip>
  );
}

import React from 'react';
import {
  IconButton,
  makeStyles,
  Popover,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export default function CopyButton({ copyContent, timeout = 3000 }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = (event) => {
    window.navigator.clipboard.writeText(String(copyContent));
    handlePopoverOpen(event);
    setTimeout(handlePopoverClose, timeout);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <Tooltip title="Copy">
        <IconButton onClick={handleCopy}>
          <FileCopy />
        </IconButton>
      </Tooltip>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>Copied!</Typography>
      </Popover>
    </>
  );
}

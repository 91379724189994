import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

export const config = {
  apiKey: 'AIzaSyCadWK1eqRZbjtyDcCZkyorFouYL2Dv-No',
  authDomain: 'ridgeline-5c2b1.firebaseapp.com',
  projectId: 'ridgeline-5c2b1',
  storageBucket: 'ridgeline-5c2b1.appspot.com',
  messagingSenderId: '470717308312',
  appId: '1:470717308312:web:dd9c8c6530ad4722649fed',
  measurementId: 'G-793ZYEWVSQ',
};

export const firebaseApp = firebase.initializeApp(config);

const db = firebase.firestore(firebaseApp);
export const analytics = firebase.analytics(firebaseApp);
export const functions = firebase.functions(firebaseApp);

// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
  db.useEmulator('localhost', 8080);
  functions.useEmulator('localhost', 5001);
  firebase
    .auth()
    .useEmulator('http://localhost:9099/', { disableWarnings: true });
  analytics.setAnalyticsCollectionEnabled(false);
}

export default firebase;
